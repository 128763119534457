import React, {Suspense} from 'react';
import Router from "./routers/index.router";
import 'antd/dist/antd.css';
import './App.css';

import PageLoadingComponent from "./components/page-loading/page-loading.component";

function App() {

  return (
      <Suspense fallback={<PageLoadingComponent/>}>
        <Router/>
      </Suspense>
  );
}

export default App;
