import {BrowserRouter, Switch, Route} from 'react-router-dom';
import React from "react";

import loadable from "../utils/loadable.util";

const MainPage = loadable(() => import('../pages/main/main.page'));
const OwnerPage = loadable(() => import('../pages/owner/owner.page'));

const BasicRoute = () => (
    <BrowserRouter>
        <Switch>
            <Route exact path="/" component={MainPage}/>
            <Route exact path="/owner" component={OwnerPage}/>
        </Switch>
    </BrowserRouter>
);

export default BasicRoute;
